import styled from "styled-components/macro";

import { DiscordIcon, GithubIcon, TelegramIcon, TwitterIcon } from "./Icons";
import {links} from "../App";
const BREAKPOINTS = {
  xs: 396,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1920,
};

export const AmmosIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    viewBox="0 0 1080 1080"
  >
    <path
      d="M0 0h1920v1080H0z"
      style={{
        fill: "none",
      }}
      transform="scale(.5625 1)"
    />
    <circle
      cx={30675.1}
      cy={17790.8}
      r={370.805}
      transform="matrix(1.2952 0 0 1.29519 -39190.275 -22502.5)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#a)",
        strokeWidth: "8.01px",
      }}
      transform="translate(-35834.883 -20556.51) scale(1.18581)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#b)",
        strokeWidth: "8.44px",
      }}
      transform="matrix(1.12593 0 0 1.12593 -33998.115 -19491.653)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#c)",
        strokeWidth: "8.89px",
      }}
      transform="matrix(1.06908 0 0 1.06908 -32254.13 -18480.631)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#d)",
        strokeWidth: "9.36px",
      }}
      transform="matrix(1.0151 0 0 1.0151 -30598.19 -17520.6)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#e)",
        strokeWidth: "9.86px",
      }}
      transform="translate(-29025.828 -16609.067) scale(.96384)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#f)",
        strokeWidth: "10.38px",
      }}
      transform="matrix(.91517 0 0 .91517 -27532.908 -15743.545)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#g)",
        strokeWidth: "10.93px",
      }}
      transform="matrix(.86896 0 0 .86896 -26115.361 -14921.778)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#h)",
        strokeWidth: "11.51px",
      }}
      transform="translate(-24769.386 -14141.515) scale(.82508)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#i)",
        strokeWidth: "12.13px",
      }}
      transform="matrix(.78342 0 0 .78342 -23491.379 -13400.621)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#j)",
        strokeWidth: "12.77px",
      }}
      transform="matrix(.74386 0 0 .74386 -22277.94 -12697.08)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#k)",
        strokeWidth: "13.45px",
      }}
      transform="matrix(.7063 0 0 .7063 -21125.732 -12029.113)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#l)",
        strokeWidth: "14.17px",
      }}
      transform="matrix(.67063 0 0 .67063 -20031.756 -11394.907)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#m)",
        strokeWidth: "14.92px",
      }}
      transform="matrix(.63677 0 0 .63677 -18992.942 -10792.705)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#n)",
        strokeWidth: "15.71px",
      }}
      transform="matrix(.60462 0 0 .60461 -18006.622 -10220.907)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#o)",
        strokeWidth: "16.55px",
      }}
      transform="matrix(.57409 0 0 .57409 -17070.129 -9677.972)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#p)",
        strokeWidth: "17.43px",
      }}
      transform="matrix(.5451 0 0 .5451 -16180.927 -9162.465)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#q)",
        strokeWidth: "18.35px",
      }}
      transform="translate(-15336.549 -8672.986) scale(.51757)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#r)",
        strokeWidth: "19.33px",
      }}
      transform="matrix(.49144 0 0 .49144 -14534.86 -8208.22)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#s)",
        strokeWidth: "20.36px",
      }}
      transform="translate(-13773.658 -7766.92) scale(.46662)"
    />
    <path
      d="M30694.2 17515.1c-90.7 19.5-195.9-88.6-321.8-31.4-133.5 60.6-152.2 199.7-146.4 411 5.4 200.9 145.4 240.5 230.5 234.9 97.7-6.3 133.3-70.7 238.9-82.4 167-18.4 234.3 41.9 323.9-15.3 70.4-45 111-188.6 105.4-304.8-6.1-126.4-62.7-243.4-150.2-280.7-91.3-38.9-191.5 49.5-280.3 68.7Z"
      style={{
        fill: "none",
        stroke: "url(#t)",
        strokeWidth: "21.44px",
      }}
      transform="matrix(.44306 0 0 .44306 -13050.878 -7347.903)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="f"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="h"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="i"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="j"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="k"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="l"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="m"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="n"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="o"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="p"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="q"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="r"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="s"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="t"
        x1={0}
        x2={1}
        y1={0}
        y2={0}
        gradientTransform="matrix(880.672 0 0 727.111 30225.1 17783.3)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#e9d69c",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.16}
          style={{
            stopColor: "#db8840",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.33}
          style={{
            stopColor: "#d76f22",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.62}
          style={{
            stopColor: "#edf6fa",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#ecc384",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
  </svg>
);

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  max-width: 1440px;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoSectionLeft = styled(LogoSection)`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: flex;
  }
`;

const LogoSectionBottom = styled(LogoSection)`
  display: flex;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;

const SocialLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px auto 0 auto;
`;

const SocialLink = styled.a`
  display: flex;
`;

const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 200px;
  margin: 20px 0 0 0;
  @media screen and (min-width: ${BREAKPOINTS.xl}px) {
    margin: 0;
  }
`;

const LinkGroupTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
`;

const ExternalTextLink = styled.a`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.textSecondary};
`;

const Copyright = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin: 1rem 0 0 0;
  color: ${({ theme }) => theme.textTertiary};
`;

const LogoSectionContent = () => {
  const LogoSectionBottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      flex-direction: row;
    }
  `;

  return (
    <>
      <LogoSectionBottomWrapper>
        <div style={{ width: "100px" }}>
          <AmmosIcon />
        </div>
        <div style={{ width: "fit-content" }}>
          <SocialLinks>
            <SocialLink
              href={`${links.discord}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DiscordIcon size={32} />
            </SocialLink>
            <SocialLink
              href={`${links.twitter}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon size={32} />
            </SocialLink>
            <SocialLink
              href={`${links.github}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubIcon size={32} />
            </SocialLink>
            <SocialLink
              href={`${links.telegram}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon size={32} />
            </SocialLink>
          </SocialLinks>
        </div>
      </LogoSectionBottomWrapper>
      <Copyright>© {new Date().getFullYear()} Ammos Finance</Copyright>
    </>
  );
};

export const AboutFooter = () => {
  return (
    <Footer>
      <LogoSectionLeft>
        <LogoSectionContent />
      </LogoSectionLeft>

      <FooterLinks>
        <LinkGroup>
          <LinkGroupTitle>Communities</LinkGroupTitle>
          <ExternalTextLink href={`${links.telegram}`}>
            Telegram
          </ExternalTextLink>
          <ExternalTextLink href={`${links.discord}`}>Discord</ExternalTextLink>
          <ExternalTextLink href={`${links.twitter}`}>Twitter</ExternalTextLink>
        </LinkGroup>
        <LinkGroup>
          <LinkGroupTitle>Learn</LinkGroupTitle>
          <ExternalTextLink href={`${links.docs}`}>Docs</ExternalTextLink>
        </LinkGroup>
      </FooterLinks>

      <LogoSectionBottom>
        <LogoSectionContent />
      </LogoSectionBottom>
    </Footer>
  );
};
